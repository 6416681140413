.swal-modal {
  -webkit-transform: inherit !important;
  -moz-transform: inherit !important;
  -o-transform: inherit !important;
  transform: inherit !important;
  animation: inherit !important;
  -webkit-animation: inherit !important;
}

.small-editor .ck-editor__editable_inline {
  min-height: 200px;
}

.rdw-storybook-custom-option {
  border: 1px solid #f1f1f1;
  padding: 3px 10px;
  height: 22px;
}

.editor-wrapper {
  border: 1px solid #aaa;
  border-radius: 10px;
}

.editor-toolbar,
.rdw-option-wrapper {
  background-color: #efefef !important;
}

.editor-toolbar {
  border-radius: 10px 10px 0px 0px !important;
}

.editor-textbox {
  padding: 10px 40px;
  min-height: 200px;
}

.centerElem {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.visibleBox {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-around;
}

.visibleBox img {
  margin-bottom: 20px;
}

.centerElemOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  opacity: 0.93;
}

.page-view-container {
  display: none;
}

.find-job .banner {
  /* background: url('../images/homepage/banner.jpg') no-repeat center center fixed; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 35rem;
}

.find-job .banner h1 {
  color: var(--white-color);
}

.find-job .banner h1::after {
  content: unset;
}

.search-bar {
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.1);
  padding: 2.5rem;
}

.search-bar .input-group svg {
  color: var(--theme-blue);
}

.search-bar select {
  height: 3.7rem;
  border-radius: 0.1rem;
  width: 33rem;
  margin-right: -7rem;
  font-size: var(--general-size);
}

.search-bar button {
  background: var(--theme-blue) !important;
  border-color: var(--theme-blue) !important;
  border-radius: 0.1rem;
  width: 20rem;
  color: var(--white-color);
  font-size: var(--general-size);
}

.search-bar button:hover {
  box-shadow: 0 2px 6px 0 var(--theme-blue) !important;
}

.find-job-body .find-job-left-nav {
  width: 25%;
}

.find-job-body .find-job-left-nav ul li h1 {
  font-size: 2.2rem;
}

.find-job-body .find-job-left-nav ul .parent-nav {
  padding: 1rem 0;
}

.find-job-body .find-job-left-nav ul .parent-nav a {
  color: var(--theme-darkblue);
  font-size: var(--general-size);
}

.find-job-body .find-job-left-nav ul .parent-nav .nav-link {
  font-size: var(--placeholder-size);
  padding-bottom: 1rem;
}

.find-job-body .find-job-left-nav ul .parent-nav .nav-link svg {
  font-size: 0.6rem;
  margin-bottom: 0.25rem;
}

.find-job-body .find-job-left-nav ul .parent-nav p {
  margin-left: 2rem;
}

.find-job-body .find-job-left-nav .white-bg {
  height: unset;
}

.find-job-body .find-job-right-content {
  width: 75%;
}

.find-job-body .headings {
  margin: 6.5rem 0;
}

.find-job-body .headings h1::after {
  left: 50%;
  transform: translateX(-50%);
}

.find-job-body .headings p {
  font-size: var(--placeholder-size);
  color: var(--light-color);
}

.job-description h2 {
  border-bottom: 1px solid var(--theme-darkblue);
}

.job-description p {
  font-size: var(--general-size);
  color: var(--light-color);
  margin: 1rem 0;
  height: 11vh;
  overflow: hidden;
  text-overflow: ellipsis;
}

.job-description .table td {
  color: var(--light-color);
  font-size: var(--placeholder-size);
}

.find-job-pagination .pagination-left p,
.find-job-pagination .pagination-right span {
  color: var(--light-color);
  font-size: var(--placeholder-size);
}

.find-job-pagination .pagination-right span {
  padding: 0 0.6rem;
  width: 3rem;
  height: 3rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.find-job-pagination .pagination-right span:hover {
  cursor: pointer;
}

.find-job-pagination .pagination-right .pagination-active {
  background: var(--theme-darkblue);
  color: var(--white-color);
  border-radius: 50%;
}

.post-content-container figure.size-large img {
  width: 100%;
}

.post-content-container img {
  width: 100%;
}

.post-content-container p {
  text-align: justify;
  line-height: 1.7;
  font-size: 16px;
}

.post-content-container ul,
.post-content-container ol,
.faq-list-container ul,
.faq-list-container ol {
  padding-left: 50px;
  margin-top: 10px;
  list-style: auto;
  line-height: 2;
}

.post-content-container h2,
.post-content-container h4 {
  color: #0093d1;
  margin-bottom: 20px;
}

.blog-single-content .post_title {
  color: #0093d1;
  font-weight: bolder;
  margin-bottom: 10px;
}

.object-contain {
  object-fit: contain !important;
}

.post-content-container img,
.post-content-container figure,
.post-content-container {
  padding-bottom: 0px !important;
  width: 100% !important;
  height: 100% !important;
}

.post-content-container .se-video-container figure {
  height: 350px !important;
}

.default-css ol,
.default-css ol li,
.post-content-container ol,
.post-content-container ol li,
.faq ul,
.faq ol,
.faq ol li,
.faq ul li {
  list-style: auto;
  all: revert;
  line-height: 2;
  font-size: 16px;
}

.default-css ul,
.default-css ul li,
.post-content-container ul,
.post-content-container ul li {
  list-style: disc !important;
  all: revert;
  line-height: 2;
  font-size: 16px;
}

.default-css * {
  font-size: initial;
}

.default-css h1 {
  font-size: 3rem !important;
  color: #0093d1 !important;
  margin-bottom: 1rem;
}

.default-css h2 {
  font-size: 2.7rem !important;
  color: #0093d1 !important;
  margin-bottom: 1rem;
}

.default-css h3 {
  font-size: 2.2rem !important;
  color: #0093d1 !important;
  margin-bottom: 1rem;
}

.default-css h4:not(.big-typo) {
  font-size: 2.07rem !important;
  color: #0093d1 !important;
  margin-bottom: 1rem;
}

.default-css h5 {
  font-size: 2rem !important;
  color: #0093d1 !important;
  margin-bottom: 1rem;
}

.default-css h6 {
  font-size: 1.5rem !important;
  color: #0093d1 !important;
  margin-bottom: 1rem;
}
.author-text ul {
  margin-top: 5px;
}
.big-typo {
  color: #004c70 !important;
  margin-bottom: 1rem;
}

.editor-textbox {
  max-height: 350px;
}

.dynamic-content-heading {
  color: #43a047;
  font-weight: bold;
}

.get-start-btn {
  font-weight: bold;
  padding: 10px 20px;
  margin-top: 30px;
  border-radius: 100px;
  font-size: 16px;
}

.reset-a {
  color: inherit;
}

.reset-a:hover {
  color: inherit;
}

.author-box {
  display: flex;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 10px;
  margin-bottom: 50px;
}

.author-head {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
}

.author-text {
  text-align: justify;
  color: #777;
}

.author-box img {
  width: 100px;
  height: 100px;
}

.author-details-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  margin-left: 20px;
}

.author-details-section .author-head {
  padding-top: 10px;
}

.social-icon-box {
  margin-bottom: 0px;
}

.social-container {
  width: 40px !important;
  height: 40px !important;
}

.ellipsis-apply {
  text-overflow: ellipsis;
  width: 195px;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.big-text {
  font-size: 6rem;
  margin: auto;
  text-align: center;
  display: block;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 250px;
  overflow: hidden;
}
.virtual_protection {
  background-color: white;
}
.virtual_protection h2 {
  font-weight: 700;
  font-size: 30px;
  /* line-height: 55px; */
  color: #004c70;
  margin-bottom: 20px;
}
.virtual_protection p {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  /* margin-bottom:14px ; */
}
.res_img {
  margin: auto;
}
.protection_btn2 {
  font-size: 22px;
  padding: 0px 20px;
}
.protection_btn2 img {
  margin-bottom: 5px;
  width: 20px;
}
.protection_btn {
  background: #49b247;
  border-radius: 17px;
  color: white;
  font-weight: 700;
  font-size: 28px;
  line-height: 43px;
  border: 0px;
  padding: 4px 20px;
}
.protection_btn {
  font-size: 22px;
  padding: 0px 20px;
}
.protection_btn img {
  margin-bottom: 5px;
  width: 24px;
}
.mike-bio-blog {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
}
.mike-bio-blog .author-head {
  margin-bottom: 5px;
}
@media only screen and (max-width: 500px) {
  .mike-bio-blog {
    display: block;
    text-align: center;
  }
  .mike-bio-blog .author-head {
    margin-bottom: 10px;
  }
  .author-details-section {
    margin: 0px 10px;
  }
  .virtual_protection .row div {
    margin-bottom: 10px;
  }
  .hide_image {
    display: none;
  }
  .virtual_protection h2 {
    margin-top: 30px;
  }
  .res_img {
    margin-bottom: 25px !important;
  }
  .blog-singlepage-banner {
    height: auto;
  }

  .blog-singlepage-banner .img-fluid {
    margin-top: 60px;
  }
}

.tw-align-center .ui.card {
  width: 100% !important;
}

/* site-admin-module */
/* 
@media  (max-width:1080px) and (min-width:320px){
  .ui.form.formflex {
    
      display: flex;
      flex-wrap: wrap;
      overflow: scroll;
  }
  .from-80{
      width: 100% !important;
  }
  .from-40{
      width: 100% !important;
      margin-bottom: 100px!important;
  }
  } */

.job-description p {
  height: auto !important;
}

.width50 {
  width: 50% !important;
}

.maxwidth {
  max-width: 800px !important;
}

@media (max-width: 1160px) and (min-width: 320px) {
  .widthhalf {
    width: 100% !important;
  }

  .flex-wrappop {
    flex-wrap: wrap !important;
  }

  .closepos {
    position: relative !important;
    z-index: 999 !important;
    top: -402px !important;
  }

  .orposition {
    top: 45.5% !important;
    left: 0% !important;
    right: 0% !important;
    margin: 0 auto !important;
    width: 50px !important;
  }
  .orposition p {
    padding: 0px 15px !important;
  }

  /* .topmodal.modal {
      top: 20% !important;
  } */
}

@media (max-width: 1160px) and (min-width: 481px) {
  .orposition {
    top: 45.5% !important;
    left: 0% !important;
    right: 0% !important;
    margin: 0 auto !important;
    width: 50px !important;
  }
}

.formflex {
  height: calc(100% - 100px);
}

.theme-0-16006-15 {
  color: #ffffff;
  font-size: 18px;
  font-family: "Arimo", sans-serif;
  border-color: hsl(122.60000000000002, 41%, 44.5%);
  outline-width: 0px;
  background-color: hsl(122.60000000000002, 41%, 44.5%);
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%),
    0 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 50em;
}
@media (width < 800px) {
  .podcast-list .single-blog-area-podcast-section {
    display: flex;
    flex-wrap: wrap;
  }
  .podcast-list .single-blog-area-podcast-section a {
    width: 40%;
    padding: 20px 0px;
  }
  .single-blog-area-podcast-section span {
    text-align: left;
  }
}
@media (width < 500px) {
  .hide-img {
    display: none;
  }
}

.custom-scrollbar {
  overflow-y: scroll;
}

.custom-scrollbar::-webkit-scrollbar {
  display: none;
}

.custom-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}